body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

.Project_table{

}

.Project_Value_Lable{
  width: 15em;
  padding-right: 1rem;
}

.Project_Table_Item {
  border-bottom-style: solid;
  border-color: black;
  border-width: 1px;
}